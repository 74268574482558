import { Controls, Player } from '@lottiefiles/react-lottie-player';
import classNames from 'classnames';
import React from 'react';

interface LoadingProps {
  size?: 'small' | 'large' | number;
  className?: string;
}

const Loading: React.FC<LoadingProps> = ({ size = 'small', className }) => {
  const style = React.useMemo(() => {
    let width = 24;
    let height = 24;
    switch (size) {
      case 'small':
        width = height = 20;
        break;
      case 'large':
        width = height = 40;
        break;
      default:
        width = height = size;
    }
    return { width, height };
  }, [size]);

  return (
    <span className={classNames('inline-block', className)}>
      <Player autoplay loop src="/images/loading.json" style={style}>
        <Controls visible={false} buttons={['play', 'repeat', 'frame', 'debug']} />
      </Player>
    </span>
  );
};

export default Loading;
