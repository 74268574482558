import { Button, Col, Input, Row } from 'antd';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React from 'react';

import { useTabletOrDesktop } from '#components/hooks/breakpoints';
import Icon from '#components/Icon';
import { Paragraph, Title } from '#components/Typography';
import { User } from '#models/model-types/UserModel';

import DiscoverItem from './DiscoverItem';

const SCROLL_OFFSET = 700;

interface IntroProps {
  creators: User[];
}

const Intro: React.FC<IntroProps> = ({ creators }) => {
  const { t } = useTranslation('home');
  const isTabletOrDesktop = useTabletOrDesktop();
  const router = useRouter();

  const ref = React.useRef<HTMLDivElement>(null);

  const scroll = React.useCallback((offset: number) => {
    if (ref.current) {
      ref.current.scrollTo({
        left: ref.current.scrollLeft + offset,
        behavior: 'smooth',
      });
      // ref.current.scrollLeft += offset;
    }
  }, []);

  const handleScrollLeft = React.useCallback(() => scroll(-SCROLL_OFFSET), [scroll]);

  const handleScrollRight = React.useCallback(() => scroll(SCROLL_OFFSET), [scroll]);

  const [searchText, setSearchText] = React.useState('');

  const handleSearchTextChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setSearchText(e.target.value),
    [],
  );

  const handleSearch = React.useCallback(() => {
    router.push(`/creators?search=${searchText.replace(/[^A-zÀ-ú0-9_.\s]/gi, '')}`);
  }, [searchText]);

  return (
    <Row gutter={[48, 16]} className="discover relative container mx-auto py-36">
      <Col span={24} className="flex flex-col items-center">
        <Title level={2} preset={isTabletOrDesktop ? 'semibold28' : 'bold32'} className="text-center mb-0">
          {t('title.discover')}
        </Title>

        <Paragraph
          preset={isTabletOrDesktop ? 'regular20' : 'regular14'}
          className="text-center opacity-80 mt-8 md:max-w-736"
        >
          {t('msg.discover')}
        </Paragraph>
      </Col>

      <Col span={24} className="px-0 flex flex-col">
        <div className="px-24 flex flex-col">
          <Input
            placeholder={t('hint.searchCreators')}
            prefix={<Icon name="search" className="" />}
            allowClear={false}
            value={searchText}
            onChange={handleSearchTextChange}
            onPressEnter={handleSearch}
            className="search-bar"
          />
        </div>

        {isTabletOrDesktop && (
          <div className="self-end space-x-16 mb-8">
            <Button type="text" icon={<Icon name="arrow-left" width={24} height={24} />} onClick={handleScrollLeft} />
            <Button type="text" icon={<Icon name="arrow-right" width={24} height={24} />} onClick={handleScrollRight} />
          </div>
        )}

        <div className="xs:mt-24 relative overflow-hidden">
          <div className="blur-bar-left" />

          <div className="creators-list" ref={ref}>
            {creators.map((creator) => (
              <DiscoverItem key={creator.uid} creator={creator} />
            ))}

            <div className="h-208 ml-8">&nbsp;</div>
            <div className="h-208 ml-8">&nbsp;</div>
          </div>

          <div className="blur-bar-right" />
        </div>
      </Col>
    </Row>
  );
};

export default Intro;
