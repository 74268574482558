import { Button, Col, Modal as AntdModal, Row } from 'antd';
import { ModalProps as AntdModalProps } from 'antd/lib/modal';
import classNames from 'classnames';
import React from 'react';

import Icon from '#components/Icon';
import { Title } from '#components/Typography';

interface ModalProps extends AntdModalProps {
  showBackButton?: boolean;
  onBack?: () => void;
  titleBorderless?: boolean;
}

const Modal: React.FC<React.PropsWithChildren<ModalProps>> = ({
  children,
  showBackButton = false,
  onBack,
  title,
  titleBorderless = false,
  className,
  centered = true,
  closable,
  footer = null,
  ...props
}) => {
  const modalTitle = React.useMemo(() => {
    if (!title && !showBackButton && !closable) return null;
    return (
      <Row align="middle" className="min-h-28">
        {showBackButton && (
          <Col className="-ml-12">
            <Button onClick={onBack} type="text" size="small">
              <Row align="middle">
                <Icon name="back" />
              </Row>
            </Button>
          </Col>
        )}

        <Col flex={1}>
          {typeof title === 'string' ? (
            <Title level={3} preset="semibold18" className="mb-0 text-left">
              {title}
            </Title>
          ) : (
            title
          )}
        </Col>
      </Row>
    );
  }, [title, closable, showBackButton, onBack]);

  return (
    <AntdModal
      title={modalTitle}
      className={classNames('app-modal', { 'title-borderless': titleBorderless }, className)}
      closeIcon={<Icon name="close-circle" />}
      centered={centered}
      closable={closable}
      footer={footer}
      {...props}
    >
      {children}
    </AntdModal>
  );
};

export default Modal;
