import React from 'react';

import Avatar from '#components/Avatar';
import { useTabletOrDesktop } from '#components/hooks/breakpoints';
import { Link, Paragraph, Title } from '#components/Typography';
import { User } from '#models/model-types/UserModel';

interface DiscoverItemProps {
  creator: User;
}

const DiscoverItem: React.FC<DiscoverItemProps> = ({ creator }) => {
  const isTabletOrDesktop = useTabletOrDesktop();

  return (
    <Link className="flex flex-col items-center w-216 mx-12 my-12" href={`/creators/${creator.creatorSlug}`}>
      <Avatar
        url={creator.creatorPhotoUrl}
        name={creator.creatorFullName}
        size={isTabletOrDesktop ? 110 : 76}
        className="border-2 border-primary rounded-full bg-white p-4 z-10"
      />

      <div className="discover-item-card">
        <Title
          level={3}
          preset={isTabletOrDesktop ? 'semibold20' : 'semibold18'}
          className="w-full text-center my-0"
          ellipsis={true}
        >
          {creator.creatorFullName}
        </Title>

        <Title
          level={4}
          preset={isTabletOrDesktop ? 'regular16' : 'regular14'}
          className="w-full text-center text-primary my-0"
          ellipsis={true}
        >
          @{creator.creatorUsername}
        </Title>

        <Paragraph
          preset="regular14"
          className="w-full text-center opacity-80 h-80 mt-8 mb-0 whitespace-normal"
          ellipsis={{ rows: 4 }}
        >
          {creator.bio}
        </Paragraph>
      </div>
    </Link>
  );
};

export default DiscoverItem;
