import { Button, Col, Input, Row } from 'antd';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React from 'react';

import { useTabletOrDesktop } from '#components/hooks/breakpoints';
import { Paragraph, Title } from '#components/Typography';

const Cause: React.FC = () => {
  const { t } = useTranslation('home');
  const isTabletOrDesktop = useTabletOrDesktop();
  const router = useRouter();

  const handleTheCauseClick = React.useCallback(() => {
    router.push('/giving-back');
  }, []);

  return (
    <div className="cause relative py-36 xs:pt-122 xs:pb-67">
      <Row gutter={[48, 24]} className="container mx-auto">
        <Col span={24} className="flex flex-col items-center">
          <Title level={2} preset={isTabletOrDesktop ? 'semibold28' : 'bold32'} className="text-white text-center mb-0">
            {t('title.cause')}
          </Title>

          <Paragraph preset={isTabletOrDesktop ? 'regular20' : 'regular14'} className="desc">
            {t('msg.cause')}
          </Paragraph>

          <Button shape="round" type="primary" className="mt-32 md:min-w-300" onClick={handleTheCauseClick}>
            {t('title.theCause')}
          </Button>
        </Col>

        <Col span={24} className="px-0">
          <div className="xs:whitespace-nowrap xs:overflow-x-auto px-24 xs:-ml-8 -ml-24 text-center">
            <div className="inline-block bg-white rounded-8 xs:w-136 w-200 xs:h-72 h-100 mt-24 xs:mx-8 mx-12">
              <img
                alt=""
                src="/images/img-unicef.webp"
                width={136}
                height={72}
                className="w-full h-full object-contain"
              />
            </div>

            <div className="inline-block bg-white rounded-8 xs:w-136 w-200 xs:h-72 h-100 mt-24 xs:mx-8 mx-12">
              <img
                alt=""
                src="/images/img-naacp.webp"
                width={136}
                height={72}
                className="w-full h-full object-contain"
              />
            </div>

            <div className="inline-block bg-white rounded-8 xs:w-136 w-200 xs:h-72 h-100 mt-24 xs:mx-8 mx-12">
              <img alt="" src="/images/img-nbn.webp" width={136} height={72} className="w-full h-full object-contain" />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Cause;
