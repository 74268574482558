import { Col, Row } from 'antd';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import React from 'react';

import Avatar from '#components/Avatar/Avatar';
import { useTabletOrDesktop } from '#components/hooks/breakpoints';
import { Link, Text } from '#components/Typography';
import { User } from '#models/model-types/UserModel';

interface IntroCreatorsProps {
  creators: User[];
  className?: string;
}

const IntroCreators: React.FC<IntroCreatorsProps> = ({ creators, className }) => {
  const isTabletOrDesktop = useTabletOrDesktop();
  const router = useRouter();

  return (
    <Row gutter={32} wrap={false} className={classNames('overflow-hidden', className)}>
      {creators.map((creator) => (
        <Col key={creator.uid}>
          <Link className="flex flex-col items-center" href={`/creators/${creator.creatorSlug}`}>
            <Avatar
              url={creator.creatorPhotoUrl}
              name={creator.creatorFullName}
              size={isTabletOrDesktop ? 133 : 98}
              className="border-3 border-primary rounded-full bg-white p-3"
            />

            <Text preset={isTabletOrDesktop ? 'regular18' : 'regular14'} className="inline-block mt-8" ellipsis={true}>
              @{creator.creatorUsername}
            </Text>
          </Link>
        </Col>
      ))}
    </Row>
  );
};

export default IntroCreators;
