import { Button, Col, Input, notification, Row } from 'antd';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React from 'react';

import { useTabletOrDesktop } from '#components/hooks/breakpoints';
import Loading from '#components/Loading';
import Modal from '#components/Modal';
import { Link, Paragraph, Text, Title } from '#components/Typography';
import { User } from '#models/model-types/UserModel';

interface ApplicationProps {
  user?: User;
  apply: (email: string) => Promise<string>;
}

const Application: React.FC<ApplicationProps> = ({ user, apply }) => {
  const { t } = useTranslation('home');
  const router = useRouter();
  const isTabletOrDesktop = useTabletOrDesktop();

  const [email, setEmail] = React.useState('');
  const [requesting, setRequesting] = React.useState(false);
  const [confirmModalVisible, setConfirmModalVisible] = React.useState(false);

  const handleEmailChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value), []);

  const handleConfirmCancel = React.useCallback(() => setConfirmModalVisible(false), []);

  const handleBecomeCreator = React.useCallback(() => {
    router.push(`/application?uid=${user?.uid}`);
  }, [user?.uid]);

  const handleApply = React.useCallback(async () => {
    if (!email) return;

    setRequesting(true);
    try {
      const link = await apply(email);
      setEmail('');
      // notification.success({ message: t('msg.requestApplicationSuccess') });
      router.push(link);
    } catch (err) {
      if (err.statusCode === 400) {
        setConfirmModalVisible(true);
        return;
      }
      notification.error({ message: err.message });
    } finally {
      setRequesting(false);
    }
  }, [email]);

  const handleSignIn = React.useCallback(() => {
    router.push('/signin');
  }, [email]);

  return (
    <div className="application relative bg-gradient-to-b from-primary to-primary-dark">
      <Row gutter={[48, 16]} className="container mx-auto">
        <Col xs={24} sm={24} md={12} lg={10} xl={8} className="pt-36 pb-24">
          <Title level={2} preset={isTabletOrDesktop ? 'semibold28' : 'bold32'} className="text-white mb-0">
            {user?.uid ? t('title.applicationLogged') : t('title.application', { app: t('common:label.greenring') })}
          </Title>

          <Paragraph
            preset={isTabletOrDesktop ? 'regular20' : 'regular14'}
            className="text-white opacity-80 mt-16 mb-20"
          >
            {user?.uid ? t('msg.applicationLogged') : t('msg.application')}
          </Paragraph>

          {user?.uid ? (
            <Button type="default" shape="round" size="large" className="text-primary" onClick={handleBecomeCreator}>
              {t('title.application', { app: t('common:label.greenring') })}
            </Button>
          ) : (
            <Input
              size="large"
              placeholder={t('hint.enterYourEmail')}
              value={email}
              onChange={handleEmailChange}
              disabled={requesting}
              onPressEnter={handleApply}
              suffix={
                requesting ? (
                  <Loading size="small" />
                ) : (
                  <Link
                    preset="medium16"
                    className="cursor-pointer text-primary"
                    disabled={requesting}
                    onClick={handleApply}
                  >
                    {t('title.apply')}
                  </Link>
                )
              }
            />
          )}
        </Col>

        <Col xs={24} sm={24} md={12} lg={14} xl={16} className="">
          <img alt="" src="/images/bg-application.webp" width={375} height={293} className="image" />
        </Col>
      </Row>

      <Modal
        width={isTabletOrDesktop ? 472 : 350}
        visible={confirmModalVisible}
        onCancel={handleConfirmCancel}
        title={t('title.emailExist')}
        footer={null}
      >
        <Text preset="regular16">
          <span dangerouslySetInnerHTML={{ __html: t('msg.emailExist', { email }) }} />
        </Text>

        <Button type="primary" shape="round" size="large" block className="mt-32" onClick={handleSignIn}>
          {t('title.signIn')}
        </Button>

        <Link href="/application" preset="regular14" className="block text-center mt-24">
          {t('msg.createNewAccount')}
        </Link>
      </Modal>
    </div>
  );
};

export default Application;
