import { Button, Divider } from 'antd';
import chunk from 'lodash/chunk';
import { observer } from 'mobx-react-lite';
import { getSnapshot } from 'mobx-state-tree';
import { NextPage } from 'next';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React from 'react';

import HeadMeta from '#components/HeadMeta';
import { useTabletOrDesktop } from '#components/hooks/breakpoints';
import { Title } from '#components/Typography';
import MainLayout from '#layout/MainLayout';
import { useStore } from '#models';
import { User } from '#models/model-types/UserModel';

import Application from './Application';
import Cause from './Cause';
import Discover from './Discover';
import Intro from './Intro';
import IntroCreators from './IntroCreators';

export interface HomeProps {}

const Home: NextPage<HomeProps> = observer(({}) => {
  const { t } = useTranslation('home');
  const router = useRouter();
  const isTabletOrDesktop = useTabletOrDesktop();
  const {
    authStore: { user },
    creatorStore: { creators, requestApplication },
  } = useStore();
  const loggedUser = user && getSnapshot<User>(user);

  const creatorChunks = React.useMemo(() => chunk(creators, isTabletOrDesktop ? 6 : 3), [creators, isTabletOrDesktop]);

  const handleJoinClick = React.useCallback(() => {
    if (!loggedUser?.uid) {
      window.localStorage.setItem('redirect', '/creators');
      router.push('/signin');
    } else {
      router.push('/creators');
    }
  }, [loggedUser?.uid]);

  return (
    <MainLayout footerVisible className="transparent-header">
      <HeadMeta title={t('pageTitle')} />

      <div className="home">
        {isTabletOrDesktop ? (
          <img alt="" src="/images/bg-home-banner-desktop.webp" width="1470" height="535" className="banner" />
        ) : (
          <img alt="" src="/images/bg-home-banner.webp" width="320" height="402" className="banner" />
        )}

        <div className="relative container mx-auto flex flex-col items-center px-24 py-44 pb-28">
          <Title level={2} preset={isTabletOrDesktop ? 'bold40' : 'bold32'} className="text-center mb-24 md:mb-40">
            <span
              dangerouslySetInnerHTML={{
                __html: t('msg.intro', {
                  closeFriendList: `<span class='close-friend-list'>close friend list</span>`,
                  instagram: `<span class='instagram'>Instagram</span>`,
                }),
              }}
            />
          </Title>

          <Button shape="round" type="primary" className="md:min-w-300" onClick={handleJoinClick}>
            {t('title.joinNow')}
          </Button>
        </div>

        <div className="relative flex flex-col xs:items-start items-center overflow-hidden">
          <IntroCreators creators={creatorChunks[0] ?? []} className="my-16 xs:ml-0" />

          {!isTabletOrDesktop && <IntroCreators creators={creatorChunks[1] ?? []} className="my-16 -ml-60" />}
        </div>

        <Intro />

        <div className="relative container mx-auto">
          <Divider className="my-0" />
        </div>

        <Discover creators={creators} />

        <Cause />

        {!loggedUser?.isCreator && <Application apply={requestApplication} user={loggedUser} />}
      </div>
    </MainLayout>
  );
});

export default Home;
