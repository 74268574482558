import { Button, Col, Row } from 'antd';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React from 'react';

import Avatar from '#components/Avatar';
import { useTabletOrDesktop } from '#components/hooks/breakpoints';
import { Paragraph, Title } from '#components/Typography';

const Intro: React.FC = () => {
  const { t } = useTranslation('home');
  const router = useRouter();
  const isTabletOrDesktop = useTabletOrDesktop();

  const handleHowItWorksClick = React.useCallback(() => router.push('/how-it-works'), []);

  return (
    <Row gutter={[48, 36]} align="middle" className="relative container mx-auto py-36">
      <Col xs={24} sm={12} className="flex flex-col xs:items-center sm:items-start">
        <Title level={2} preset={isTabletOrDesktop ? 'bold40' : 'bold32'} className="xs:text-center sm:text-left">
          <span
            dangerouslySetInnerHTML={{
              __html: t('title.intro', {
                app: `<span class='text-primary'>${t('common:label.greenring')}</span>`,
              }),
            }}
          />
        </Title>

        <Paragraph
          preset={isTabletOrDesktop ? 'regular20' : 'regular14'}
          className="xs:text-center sm:text-left opacity-80 mb-0"
        >
          {t('msg.intro1')}
        </Paragraph>

        <Paragraph
          preset={isTabletOrDesktop ? 'regular20' : 'regular14'}
          className="xs:text-center sm:text-left opacity-80 mb-0 mt-24"
        >
          {t('msg.intro2')}
        </Paragraph>

        <Button shape="round" type="primary" className="mt-24 md:mt-40 md:min-w-300" onClick={handleHowItWorksClick}>
          {t('title.howItWorks')}
        </Button>
      </Col>

      <Col xs={24} sm={12} className="intro-simulator relative h-550 overflow-hidden">
        <img alt="" src="/images/bg-intro-simulator-circles.svg" className="circles" width={312} height={245} />

        <img
          alt=""
          src="/images/bg-intro-simulator-phone.webp"
          className="absolute top-0 left-0 right-0 bottom-0 w-full h-full object-contain"
          width={322}
          height={592}
        />

        <img alt="" src="/images/bg-intro-simulator-photo.webp" width={200} height={433} className="image" />

        <img alt="" src="/images/bg-intro-simulator-line.svg" className="line" />

        <div className="user flex flex-row items-center">
          <Avatar size={20} url="/images/bg-intro-simulator-avatar.webp" />

          <div className="text-10 ml-8">Elsie Hewitt</div>

          <div className="flex-1" />

          <div className="bg-green rounded-4 p-4 text-9 text-white font-bold">CLOSE FRIENDS</div>
        </div>
      </Col>
    </Row>
  );
};

export default Intro;
